// Detect if a link's href goes to the current page
export function getSamePageAnchor(link) {
  if (
    link.protocol !== window.location.protocol ||
    link.host !== window.location.host ||
    link.pathname !== window.location.pathname ||
    link.search !== window.location.search
  ) {
    return false;
  }

  return link.hash;
}

// Scroll to a given hash, preventing the event given if there is one
export function scrollToHash(hash, e) {
  const elem = hash ? document.querySelector(hash) : false;
  if (elem) {
    console.log(elem);
    if (e) e.preventDefault();
    scroll({
      top: elem.offsetTop,
      behavior: "smooth"
    });
  }
}